@import "../../../styles/mixins";

.tab-container {
  position: relative;
}

.recipe-list {
  &-page {
    min-height: 1000px;
  }

  &_title {
    &-text {
      font-weight: 200;
      font-size: 2.2rem;
      margin: 0;
      padding: 50px 21px 0px 39px;
    }
  }

  &_panel {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    background: var(--button-color);

    @include for-small-desktop-devices {
      justify-content: center;
    }

    &-img {
      width: 38px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      @include for-small-desktop-devices {
        margin: 0px 70px 0px 70px;
      }
    }
  }

  &_list-block {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
    margin: 20px;
  }

  &_container {
    width: 100%;
    margin: 0px 15px 28px 19px;
    position: relative;

    @include for-medium-phone-devices {
      margin: 0px 43px 28px 38px;
    }

    @include for-small-tablet-devices {
      margin: 2rem;
      max-width: 333px;
    }
  }

  &_list {
    width: 100%;
    justify-content: center;
  }

  &_img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &-container {
      padding: 0;
      width: 280px;
      text-decoration: none;
      color: var(--text-color);
      cursor: pointer;
      background: var(--button-text-color);

      &:hover {
        opacity: 0.8;
      }

      @include for-medium-phone-devices {
        width: 333px;
      }
    }

    &-block {
      background: var(--background-block-card);
      width: 100%;
      height: 232px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &_no-img {
    font-size: 10rem;
    color: var(--button-text-color);
  }

  &_label {
    width: 100%;
    padding: 15px;
    display: flex;
    font-size: 1rem;

    &-info {
      width: 100%;
      padding: 10px 15px;
      font-size: 1.5rem;
      font-family: var(--card-text-font);
      font-weight: 200;
      font-size: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
