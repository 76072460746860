@import "../../styles/mixins";

.tile_card {
  background-size: cover !important;
//   height: 50vh;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */ 
  .rich_text_for_old {
    height: 4.5rem;
    overflow: hidden;
  }
}

@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */ 
}