@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Merriweather:wght@200;300;400;500;700&family=Montserrat:wght@200;300;400;700&family=Oswald:wght@200;300;400;500;700&family=Outfit:wght@200;300;400;700&family=Quicksand:wght@300;400;700&family=Roboto:wght@300;400;700&display=swap');

@import "styleForExtend";
@import "mixins";

// TODO move to own scss file
a {
  text-decoration: none;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #262626;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #262626;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #262626;
}

.padding-on-mobile {
  @media (max-width: 768px) {
    // padding-left: 10px;
    // padding-right: 10px;
  }
}

.Toastify__toast-body {
  padding: 0 !important;
}

.Toastify__close-button--default {
  display: none;
}

.Toastify__toast {
  padding: 0;
  border-radius: 8px;
}


.contest-input-field {
  input, fieldset {
    border-radius: 0;
    border-color: #979797;
  }
}

.achievements-wysiwyg p {
  margin: 0;
}

.spin {
  animation-name: spinFast;
  animation-duration: 625ms;
  // animation-iteration-count: infinite;
  animation-timing-function: linear; 
  /* transform: rotate(3deg); */
   /* transform: rotate(0.3rad);/ */
   /* transform: rotate(3grad); */ 
   /* transform: rotate(.03turn);  */
}

@keyframes spinFast {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(720deg);
    }
}
.spin-slow {
  animation-name: spinSlow;
  animation-duration: 625ms;
  // animation-iteration-count: infinite;
  animation-timing-function: linear; 
  /* transform: rotate(3deg); */
   /* transform: rotate(0.3rad);/ */
   /* transform: rotate(3grad); */ 
   /* transform: rotate(.03turn);  */
}

@keyframes spinSlow {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(180deg);
    }
}

.tile_background {
  background-size: cover !important;
  @include for-small-tablet-devices {
    height: 50vh !important;
  }
  @include for-large-tablet-devices {
    height: 80vh !important;
  }
  @include for-medium-tablet-devices {
    height: 80vh !important;
  }
}

.header_tile
{
  background-size: cover !important;
  @include for-large-tablet-devices {
    height: 30vh !important;
  }
  @include for-medium-tablet-devices {
    height: 30vh !important;
  }
}

.text-primary {
  color: var(--primary-color);
  
}

.font-primary {
  font-family: var(--primary-font) !important;
}

.desktop-page {
  font-size: 28px;
}

.app-wrapper {
  display: flex;
  // height: 100vh;
  flex-direction: column;
  align-items: center;
}

.wrapper-with-modal {
  overflow: hidden;
}

.scan-description {
  &_info {
    @extend .page-info;

    &-img {
      width: 60%;

      @include for-medium-phone-devices {
        width: 80%;
      }

      @include for-small-tablet-devices {
        width: 70%;
      }
    }

    &-description {
      @extend .info-description;
    }
  }

  &_btn {
    @extend .action-btn;

    &-container {
      @extend .btn-container;
    }

    &-description {
      @extend .btn-description;
    }
  }
}

.scanner {
  &_info {
    @extend .page-info;

    &_icon {
      font-size: 131px;
      color: #4b4b4b;
    }

    &-description {
      @extend .info-description;
    }
  }

  &_btn {
    @extend .action-btn;

    &-container {
      @extend .btn-container;
    }

    &-description {
      @extend .btn-description;
    }
  }
}

.result {
  &_info {
    @extend .page-info;

    &-description {
      @extend .info-description;
    }
  }

  &_btn {
    @extend .action-btn;

    &-container {
      @extend .btn-container;
    }

    &-description {
      @extend .btn-description;
    }
  }

  &_level {
    &-container {
      display: flex;
      width: 100%;
      justify-content: space-around;
    }

    &-btn {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      border: 3px #979797 dashed;
      font-size: 10px;
      color: var(--text-color);
      background-color: #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @include for-medium-phone-devices {
        width: 80px;
        height: 80px;
        font-size: 12px;
      }

      @include for-large-phone-devices {
        width: 90px;
        height: 90px;
        font-size: 14px;
      }

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
      }

      &:focus {
        outline: 0;
      }

      // &:hover {
      //   background-color: $button-color;
      //   border-color: var(--logo-primary-color);
      //   color: var(--button-text-color);
      // }

      &_active {
        background-color: var(--button-color);
        border-color: var(--logo-primary-color);
        color: var(--button-text-color);
      }

      &_disabled {
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}

.recipe {
  &_title {
    font-size: 19px;
    text-align: center;
    margin: 10px 0px;
    color: var(--text-color);
  }

  &_description {
    color: var(--text-color);
    font-size: 11px;
    font-style: italic;
    margin-bottom: 10px;
    display: flex;
    padding-left: 5px;
  }

  &_img {
    width: 100%;
  }

  &_inform {
    &-panel {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      margin: 10px 0px;
      flex-wrap: wrap;

      &_item {
        color: var(--text-color);
        width: 40%;
        margin: 10px;
        display: flex;

        &-title {
          color: var(--logo-primary-color);
        }
      }
    }
  }

  &_ingridients {
    &-item {
      &_title {
        font-size: 13px;
        color: var(--logo-primary-color);
        padding: 0 5px;
      }

      &_list {
        font-size: 12px;
        color: var(--text-color);
      }
    }
  }

  &_text-block {
    &-item {
      margin: 7px 0;
      font-size: 12px;
      color: var(--text-color);
      padding: 0 5px;

      &_title {
        font-size: 13px;
        color: var(--logo-primary-color);
      }
    }
  }

  &_links {
    display: flex;
    width: 100%;
    justify-content: space-around;
    list-style-type: none;
    padding: 0px;
    color: var(--text-color);
    margin-bottom: 15px;

    &-item {
      width: 100%;
      border: black 1px solid;
      text-align: center;
      font-size: 15px;
      padding: 5px;

      &:hover {
        color: #ffff;
      }

      &:focus {
        outline: 0;
      }
    }

    &_facebook:hover {
      background-color: #37589b;
    }

    &_twitter:hover {
      background-color: #46d4fe;
    }

    &_whatsapp:hover {
      background-color: #00e676;
    }

    &_pinterest:hover {
      background-color: #cb2027;
    }

    &_linkedin:hover {
      background-color: #419cca;
    }

    &_tumblr:hover {
      background-color: #345574;
    }

    &_vk:hover {
      background-color: #597ba5;
    }

    &_reddit:hover {
      background-color: #ff4500;
    }

    &_mail:hover {
      background-color: #9fae37;
    }
  }
}

.icon {
  font-size: 26px;
  margin: 0 10px;
}

#interactive {
  video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
  }
}

.radio {
  display: none;
}

.Toastify__progress-bar--default {
  background: gray;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

strong {
  font-weight: 700;
}

.video {
    &_thumbnail {
        background-size: 'cover';
    }

  &_title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 60px 24px 60px;

    &-container {
      align-self: flex-start;
      width: 100%;
    }

    &-text {
      font-weight: 200;
      font-size: 2.2rem;
      margin: 0;
      padding: 25px 21px 0px 39px;
    }

    &-img {
      mix-blend-mode: multiply;
      width: 260px;
    }
  }
}

.video-list {
  &_list-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 20px 20px 20px;
  }

  &_list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 28px;
    padding-bottom: 100px;
    height: auto;

    @include for-small-desktop-devices {
      max-width: 1300px;
    }
  }

  &_container {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid var(--text-color);
    position: relative;
    margin: 1rem 0;

    @include for-small-tablet-devices {
      margin: 2rem;
      max-width: 353px;
    }
  }

  &_img {
    width: 100%;
    height: 100%;
    border: 0;

    &-container {
      border: none;
      padding: 0;
      width: 100%;
      text-decoration: none;
      color: var(--text-color);
    }

    &-block {
      background: var(--background-block-card);
      width: 100%;
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include for-large-phone-devices {
        height: 166px;
      }

      @include for-large-tablet-devices {
        height: 197px;
      }
    }

    &-block img {
      width: 100%;
    }
  }

  &_label {
    padding: 15px;
    display: flex;
    background: var(--button-text-color);
    font-size: 1rem;

    &-info{
      width: 100%;
      padding: 10px 15px;
      display: flex;
      background: var(--button-text-color);
      font-family: var(--card-text-font);
      font-weight: 200;
      font-size: 13px;
      line-height: 17px;
    }
  }
}


.nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.nav.visible {
  transform: translateX(0);
}

.nav-black {
  background-color: #000;
  width: 100%;
  max-width: 500px;
  transition-delay: 0.4s;
}

.nav-black.visible {
  transition-delay: 0s;
}

.nav-red {
  background-color: var(--primary-color);
  width: 95%;
  transition-delay: 0.2s;
}

.nav-red.visible {
  transition-delay: 0.2s;
}

.nav-white {
  background-color: #fff;
  width: 95%;
  position: relative;
  transition-delay: 0s;

  overflow-y: auto;
}

.nav-white.visible {
  transition-delay: 0.4s;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

